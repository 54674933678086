const { default: Swiper } = require('swiper/bundle');

document.addEventListener("DOMContentLoaded", function(event) {

    var swiper = new Swiper('.featured-swiper', {
        loop: true,
        centeredSlides: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.featured-swiper-pagination',
            clickable: true,
          },
        navigation: {
          nextEl: '.featured-swiper-button-next',
          prevEl: '.featured-swiper-button-prev',
        },
        breakpoints:{
            360:{
                slidesPerView:1,
                spaceBetween:0,
            },
            640:{ //sm size
                slidesPerView:1.3,
                spaceBetween:20,
            },
        }
      });


    let dropdownActive = false;
    const dropdownBtn = document.querySelectorAll('button[data-action=dropdown]');

    dropdownBtn.forEach(function($e) {
        const dropdownMenuId = $e.dataset.target;
        const $dropdownMenu = document.getElementById(dropdownMenuId);

        $e.addEventListener("click", function(event) {
            event.preventDefault();
            $dropdownMenu.classList.toggle('hidden');

            dropdownActive = !$dropdownMenu.classList.contains('hidden');
        });
    });

    window.addEventListener("mouseup", function(event) {
        if(!dropdownActive) {
            return;
        }

        dropdownBtn.forEach(function($e) {
            const dropdownMenuId = $e.dataset.target;
            const $dropdownMenu = document.getElementById(dropdownMenuId);

            if(event.path.includes($e) || event.path.includes($dropdownMenu)) {
                return;
            }

            $dropdownMenu.classList.add('hidden');
        });
    });

    // Only show Purchase Ticket button when body Content is fully visible
    const bodyContent = document.querySelectorAll('.body-content')[0];
    const purchaseTicketButton = document.getElementById('purchaseTicketButton');
    if (bodyContent && purchaseTicketButton) {
        document.addEventListener('scroll', () => {
            var position = bodyContent.getBoundingClientRect();

            if(position.top <= 0) {
                // console.log('Element is fully visible in screen');
                purchaseTicketButton.classList.add('block');
                purchaseTicketButton.classList.remove('hidden');
            } else {
                // console.log('Element is not visible in screen');
                purchaseTicketButton.classList.add('hidden');
                purchaseTicketButton.classList.remove('block');
            }
        })
    }

});
